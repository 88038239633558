// @use "sass:map"; // only Dart Sass (>1.23.0) currently supports @use

$mainContainerFluid: false;

// COLORS
$logo1-c: #ED0018; // rgb(255,45,0); // $logo-c#{1}; // map.get($colorsLogo, "1");
$logo2-c: #006C49; // rgb(0,118,80); // $logo-c#{2}; // map.get($colorsLogo, "2");
$bg-logo2-c: darken($logo2-c, 10%);
$logo3-c: #000000;
$logo-c: (
	"1": $logo1-c, // rouge
	"2": $logo2-c, // vert
);
$grey-c: rgb(153, 153, 153);
$grey-light-c: lighten($grey-c, 20%);
$grey-dark-c: darken($grey-c, 20%);
$grey-bg-c: lighten($grey-c, 40%);

$link-c : lighten($logo2-c, 5%);
$link-visited-c : darken($link-c, 10%);
$link-hover-c : lighten($link-c, 10%);
$button-c: #FFF;
$button-bg-c: #000;

$link-admin-c : lighten($logo1-c, 15%);
$link-admin-visited-c : darken($link-admin-c, 10%);
$link-admin-hover-c : lighten($link-admin-c, 10%);

$text-c: #000;
$hs-c: $text-c;

$bg-c: #FFF;
$border1-c: $logo3-c;
$border2-c: #FFF;

// typo
$text-family-f: "Montserrat", Verdana, "Geneva", sans-serif; 
$text-size: 16px;
$text-weight: 500;
$alt-family-f: "EB Garamond", Georgia, Times, "Times New Roman", serif;
$hs-family-f: $text-family-f;
$h1-weight: 550;
$hs-weight: 450;
$hs-sizes: (1: 1.9rem, 2: 1.8rem, 3: 1.65rem, 4: 1.5rem, 5: 1.25rem, 6: 1rem);

// others
$border-w: 2.2px;
$border-radius: 0;// 10px;
$margin: 0.7em;
$padding: $margin;

// media-queries variables (from boostrap) 
$screen-xs:                  480px;
$screen-sm:                  768px;
$screen-md:                  992px;
$screen-lg:                  1200px;
$screen-xs-max:              ($screen-sm - 1);
$screen-sm-max:              ($screen-md - 1);
$screen-md-max:              ($screen-lg - 1); 
$screen-phone:               $screen-xs; // 480px
$screen-tablet:              $screen-sm; // 768px
$screen-desktop:             $screen-md; // 992px
$screen-desktop-lg:          $screen-lg; // 1200px
$screen-phone-max:           $screen-xs-max; // 767px
$screen-tablet-max:          $screen-sm-max; // 991px
$screen-desktop-max:         $screen-md-max; // 1199px
// @media screen and (min-width: $screen-tablet ) {}
// @media screen and (min-width: $screen-tablet ) and (max-width: $screen-tablet-max ) {}




// FONTS


// on utilise @font-face pour éviter d'utiliser google fonts...
// $family-f: "Montserrat-VariableFont_wght";
// @font-face 
// {
//     font-family: $family-f;
//     // src: url('/build/fonts/' + $family-f + '.eot'); /* IE9 Compat Modes */
//     src: 
//         // url('/build/fonts/' + $family-f + '.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//         // url('/build/fonts/' + $family-f + '.woff') format('woff'), /* Modern Browsers */
//         url('/public/build/fonts/' + $family-f + '.ttf')  format('truetype'), /* Safari, Android, iOS */
//         // url('/build/fonts/' + $family-f + '.svg#myfont') format('svg'); /* Legacy iOS */
// }

// ...néanmoins, on pourrait aussi faire : // cf. https://chriscourses.com/blog/loading-fonts-webpack
// @import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&family=Mukta:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&family=Mukta:wght@300;400;500;600;700&display=swap');


$assets-url: '~..'; // pour versionning des images de background

// MIXIN 

@mixin borders {
	border-style: solid;
	border-color: $border1-c;
	border-width: $border-w $border-w $border-w $border-w;
	border-radius: $border-radius $border-radius $border-radius $border-radius;
}

@mixin borders-top {
	@include borders;
	border-width: $border-w $border-w 0 $border-w;
	border-radius: $border-radius $border-radius 0 0;
}
@mixin borders-bottom {
	@include borders;
	border-width: 0 $border-w $border-w $border-w;
	border-radius: 0 0 $border-radius $border-radius;
}
@mixin truncate {
	overflow: hidden;
	word-wrap: break-word;
	word-break: break-all;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
	display: inline-block;
	line-height: 1.2rem;
}

// BOOTSTRAP MIXINS
// cf. https://symfony.com/doc/current/frontend/encore/bootstrap.html

// customize some Bootstrap variables
$primary: $logo1-c;

// the ~ allows you to reference things in node_modules
// @import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/mixins/_grid";
