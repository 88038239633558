// @use "sass:map"; // only Dart Sass (>1.23.0) currently supports @use

/* VARIABLES */ 

@import 'variables';

// @debug $bg-logo2-c;
// @debug adjust-hue(#6b717f, 60deg);
// @debug darken($logo2-c, 10%);


/* GÉNÉRALITÉS */ 
html {
    font-size: $text-size;
    @media screen and (max-width: $screen-desktop-max ) {
        font-size: 0.9 * $text-size;
	}
}

body {
    @if $mainContainerFluid == true {
        background: $bg-logo2-c;
        // background: ($bg-logo2-c 0%, $bg-logo2-c 100%);
    } @else {
        background: white;
    }
    background-repeat: no-repeat;
	// min-height: 100%;
	// height: 100%;
    color: $text-c;
    font-family: $text-family-f;
    font-weight: $text-weight;
    &.home {
        background: $bg-logo2-c;
    }
}

h1,h2,h3,h4,h5,h6 {
	font-family: $hs-family-f;
	font-weight: $hs-weight;
}
h1 {
	background-color: black;
	color: $white; // $logo1-c;
    font-weight: $h1-weight;
	// width: fit-content;
	background-image: url(#{$assets-url + '/medias/h1_white.png'});
	background-repeat: no-repeat;
	background-position-x: 5px;
	background-position-y: center;
	padding: 6px 0px 8px 50px;
	margin: 0 -0.5em 0.5em -0.5em; // sans arrondis
	/* // pour arrondis
    // position: relative;
	// top: 0.5em;
	// margin: 0.5em 0 1em -0.5em; 
	// border-radius: 0 5px 5px 0; 
    */
}
h2 {
	text-align: center;
	font-weight: 500;
    margin-top: 1.2rem;
    margin-bottom: 2rem;
}
h3 {
	text-align: center;
	font-weight: 500;
    margin-bottom: 1.5rem;
}
@each $name, $size in $hs-sizes {
	h#{$name} {
		font-size: $size;
	}
}

p {
	text-align: justify;
	margin-bottom: 1rem;
}

a {
	color: $link-c ;
	text-decoration: none;
	&:hover {
		color: $link-hover-c;
		text-decoration: underline;
	}
	&:visited {
		color: $link-visited-c;
	}
	&.adm-link {
		color: $link-admin-c ;
		&:hover {
			color: $link-admin-hover-c;
		}
		&:visited {
			color: $link-admin-visited-c;
		}
	}
    &.widget-link {
        p {
            color: $text-c;
        }
        &:hover {
            text-decoration: none;
            h1, h2, h3 {
                text-decoration: underline;
            }
            p {
                color: $link-c;
            }
        }
    }
}

.mgt {
	margin-top: $margin;
}
.mgb {
	margin-bottom: $margin;
}
.mg-auto {
	margin: auto;
}
.pdt {
	padding-top: $padding;
}
.pdb {
	padding-bottom: $padding;
}

label.required:after {
	content:" *";
	color: $logo1-c;
}

a.btn-icig, button.btn-icig, span.btn-icig {
	color: $button-c;
	background-color: $button-bg-c;
	&:hover {
		color: $button-c;
		background-color: $link-hover-c;
	}
	&:visited {
        color: darken($button-c, 5%);
	}
    &.inverted {
        background-color: $link-hover-c;
        &:hover {
            background-color: $button-bg-c;
        }
    }
}

div.post-medias {
	// &.thumbnails {	}
	&.slide, &.slides {
		padding-bottom: 1rem;
	}
	&.documents {
		padding: 1rem 0;
	}
}
@mixin thumbnail {
	float: left;
	width: 25%;
	@media screen and (min-width: $screen-tablet ) and (max-width: $screen-tablet-max ) {
		width: 50%;
	}
	@media screen and (max-width: $screen-phone-max ) {
		width: 100%;
	}
	max-width: 100%;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}
img.thumbnail {
	@include thumbnail;
}

.form-control {
    &:focus {
        box-shadow: 0 0 0 1px $bg-logo2-c;
        border-color: $bg-logo2-c;
    }
    &.form-control-lg {
        border-radius: 0;
    }
}

// ***
.element {
	// Three columns to start
	@include row-cols(3);
	// Five columns from medium breakpoint up
	@include media-breakpoint-up(md) {
	  	@include row-cols(5);
	}
}


/* BODY, toutes pages */
.green-bg {
    position: absolute;
    background: $bg-logo2-c;
    height: 12.8vw;
    width: 100%;
}
.white-bg {
    position: absolute;
    // background: linear-gradient($bg-c 0vw, $bg-c 12.8vw, $bg-logo2-c 50vw);
    background: $bg-c;
    top: 12.8vw;
    height: 50vw;
    width: 100%;
    z-index: -1;
    @media screen and (max-width: $screen-desktop-max ) {
        height: 70vw;
        // background: linear-gradient($bg-c 0vw, $bg-c 12.8vw, $bg-logo2-c 70vw);
    }
}

.main-container {
	height: 100%;
	$top-mg: 11.0rem;
	// margin-top: $top-mg;
    // border-top: $border-w solid $border1-c;
    // border-bottom: ($border-w - 1) solid $border1-c;

	header {
		position: relative;
		// background-color: $bg-c;
		// @include borders-top;
		// padding-top: 5vw;
		div.banner {
			// position: absolute;
			// z-index: 100;
			// height: $top-mg + 1.5vw;
			// height: $top-mg + 3.5rem;
			width: 100%;
            height: 15.5vw;
			// top: -$top-mg;
			// left: 0;
			
			$logo-width: 20%;
			a.logo {
				position: absolute;
				z-index: 120;
				left: 3%;
				display: inline-block;
				width: $logo-width;
				@include borders-bottom;
				background: white;
				img {
					width: 100%;
				}
				
			}
			div.frise {
				position: absolute;
				left: $logo-width + 3%;
				display: inline-block;
				// bottom: $banner-top-mg;
				height: 100%;
				width: 77%;
				background-image: url(#{$assets-url + '/medias/frise.png'});
				background-repeat: no-repeat;
				background-size: contain;
			}

			p.slogan {
				position: relative;
				top: $top-mg + 1.7rem;
				font-size: 0.7rem;
				left: 3%;
				font-style: italic;
			}
		}


		div.top-spacer {
			width: 100%;
			height: $top-mg - 8.0rem;
		}
		
		nav.main-menu {
			font-size: 1.25rem;
            font-weight: 500;
			padding-top: 1.0rem;
			padding-bottom: 1.9rem;
			z-index: 1000;
			&.fixed-top {
                background: $bg-c;
				padding-bottom: 1rem;
				padding-top: 0.8rem;
                border-bottom: thin solid rgba(100,100,100,0.75);
			}
			a.nav-link {
				color: black;
				display: inline-block;
				transition-property: all;
				transition-duration: 0.2s;
                border-style: solid;
                border-width: 0 0 2px 0;
                border-color: transparent;
				&:hover, &.active {
					color: $logo2-c;
					border-color: $logo1-c;
					transition-property: all;
					transition-duration: 0.2s; 
				}
			}
			ul.navbar-nav {
				margin: auto;
				li.nav-item {
					margin: 0 0.5rem;
					&.navAgenda{
                        display: none;
					}
				}
                @media screen and (max-width: $screen-tablet-max) {
                    margin-top: 0.5em;
                    li.nav-item {
                        margin: 0;
                    }
                }
			}
			div.navbar-collapse {
                &.show ul {
                    //  reported above @media screen and (max-width: $screen-tablet-max) 
                }
                li.nav-item a {
                    padding-bottom: 0;
                }
            }
            #searchForm {
                z-index: 2210;
                left: 0;
                a.nav-link {
                    padding: 0 1.2rem;
                    border: thin solid $bg-logo2-c;
                }
            }
		}
	} // /header

	$top-mg-desktop: $top-mg - 2.0rem;
	$top-mg-tablet: $top-mg - 4.8rem;
	$top-mg-phone: $top-mg - 6.5rem;

	main {
		min-height: 25rem;
		background-color: $bg-c;
		// @include borders-bottom;
        // max-width: 1200px;
        @media screen and (max-width: $screen-desktop-max ) {
            width: 100%;
            max-width: 100%;
        }

        div.chapo {
            font-family: $alt-family-f;
            color: lighten($text-c, 25%);
            font-size: 1.90rem;
            line-height: 2.30rem;
            @media screen and (max-width: $screen-tablet-max ) { 
                font-size: 1,75rem;
                line-height: 2.1rem;
            }
        }

        div.contenu {
            font-size: 1.20rem;
            img {
                position: relative;
                left: 20%;
                width: 60% !important;
                height: auto !important;
                max-height: 90vw;
                object-fit: contain;
            }
            @media screen and (max-width: $screen-tablet-max ) {
                font-size: 1.1rem;
                img {
                    position: initial;
                    left: inherit;
                    width: 100% !important;
                    height: auto !important;
                    max-height: 65vw;
                    object-fit: contain;
                }
            }
        }

		@media screen and (min-width: $screen-desktop-lg ){
			.left-column {
				text-align: center;
				// flex: 0 0 16.666667%;
				border-right: thin dotted $grey-dark-c;
			}
			.right-column {
				border-left: thin dotted $grey-dark-c;
			}
		}
		@media screen and (min-width: $screen-desktop ) and (max-width: $screen-desktop-max ) {
			.left-column, .right-column {
				border-left: none;
				border-right: thin dotted $grey-dark-c;
			}
		}
		@media screen and (max-width: $screen-tablet-max ) {
			.left-column, .right-column {
				border-left: none;
			}
		}
	}
} // /.container

footer {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 1.0rem 0 0.5rem 0;
    @if $mainContainerFluid == true {
        // background: white;
    } @else {
        background: $bg-logo2-c;
        // background: ($bg-logo2-c 0%, $bg-logo2-c 100%);
    }
    &.home {
        background: none;
    }
	ul {
		display: inline-flex;
		padding: 0;
		margin: 0;
		li {
			list-style: none;
			@media screen and (min-width: $screen-desktop ) {
				margin: 0 1.5rem;
			}

			a {
				color: $bg-c;
				font-size: 0.9em;
				// text-decoration: underline;
				// border-color: $text-c;
				// border-style: solid;
				// border-width: 0 0 1px 0;
				transition-property: all;
				transition-duration: 0.3s;
				&:visited {
					color: darken($bg-c, 10%);
				}
				&:hover {
					color: $text-c;
					// border-color: $bg-c;
					transition-property: all;
					transition-duration: 0.3s;
				}
			}
		}
	}
}

/* CLASSES Particulières */

div.pagination {
	margin-top: $margin;
	nav {
		margin: auto;
		ul {
			li a {
				color: $grey-c;
			}
		} 
	}
}

p.subtitle {
	margin-top: -0.8rem;
	font-size: 1.2rem;
	font-weight: bold;
	font-style: italic;
}

.date {
	font-style: italic;
	color: darken($grey-dark-c, 20%);
    margin: 0em 0 0.5em 0;
}

.recherche {
	// padding-top: 1rem;
	// padding-bottom: 1rem;
	// background-color: $grey-bg-c;
	label {
		display: none;
	}
}



.themes {
	.theme {
		min-height: 8rem;
		img {
			transform: scale(1);
			transition: transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s;
			&:hover {
				transform: scale(1.15);
				transition: transform 0.2s ease 0s, -webkit-transform 0.2s ease 0s;
			}
		}
	}
}

div.see-also {
	margin-top: 1rem;
	// ul {
	// 	padding-left: 1rem;
	// }
} 

div.agenda {
	@media screen and (min-width: $screen-desktop ) {
		max-height: 95rem;
		overflow-y: auto;
	}
	// div.agenda-dyn-content {
	// 	background: chartreuse;
	// }
}
div.events-list {
	p.date {
		text-align: center;
	}
	&.is-all-events {
		p.date {
			text-align: left;
			font-size: 1.3rem;
		}
	}
}
div.articles-list, div.event-list, div.questionAnswers-list, div.structures-list {
    &.with-images {
        a {
            p {
                text-align: left;
                span.date {
                }
                span.tags {
                    color: inherit;
                }
                span.title {
                    font-size: 1.1rem;
                    font-weight: bold;
                    margin: 0.8rem 0;
                    display: inline-block;
                }
            }
            &:hover p span.title {
                text-decoration: underline;
            }
        }
    }
}


div.carousel {
    .carousel-control-prev, .carousel-control-next {
        color: $grey-dark-c;

    } 
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23555' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
    }
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23555' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
    }
}


/* PAGES SPECIFIQUES */

// recherche
div.resultats-recherche-wrapper {
    @include make-row();
    section.resultats-recherche {
        @include make-col-ready();

        @include media-breakpoint-up(md) {
            @include make-col(12);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(12);
        }
        ul {
            // list-style-type: none;
            padding-left: 1rem;
            li {
                margin-top: 0.5em;
                line-height: 1.5em;
            }
        }
    } 
}

/* IMPORTS */

// @import 'pages/home'; // imported with webpack page-specific, cf. https://symfony.com/doc/current/frontend/encore/simple-example.html#page-specific-javascript-or-css-multiple-entries
@import 'pages/events';